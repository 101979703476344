// left right padding steps 4vw
//Header 8vh
//Footer 4vh
$phone: 320px;
$tablet: 768px;
$computer: 1440px;

//ANIMATIONS
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomin {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

.page {
  animation: ease-out fadein 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80vw;
}
.pageHeading {
  animation: ease-out zoomin 0.3s;
  margin-top: 6vh;
}

//COMPONENTS
.card {
  padding: 1em;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  transition: transform 0.2s;
  box-shadow: 0px 6px 16px 0px #00000014;
  background-color: white;
}
.card:hover {
  transform: scale(1.05);
}

//GLOBAL
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#ffffff 2px, transparent 2px),
    linear-gradient(to right, #ffffff 2px, transparent 2px);
  background-size: 46px 46px;
  background-color: rgba(201, 235, 250, 0.2);
  max-width: 100%;
  overflow-x: hidden;
}
.Main {
  //Centers all the pages
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 94vh;
}
.Footer {
  height: 6vh;
  width: 100vw;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home {
  width: 100%;
  margin: auto;
  a {
    text-decoration: none;
    color: black;
  }
}
.About {
  .chart {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    border-radius: 20px;
  }
  .description {
    max-width: 700px;
    border: 2px solid black;
    padding: 20px;
    align-items: center;
  }
}
.Cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 40px 0;
}
.Contact {
  width: 100%;
  max-width: 600px;
  h3 {
    text-align: center;
  }
  strong {
    margin-bottom: 30px;
  }
  .socialMedia {
    width: 80%;
    margin-bottom: 30px;
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    img {
      height: 100%;
      max-height: 50px;
      transition: transform 0.3s;
    }
    img:hover {
      transform: scale(1.5);
    }
  }
  .contactForm {
    width: 90%;
    border: 2px black solid;
    padding: 20px 30px;
  }
  .submitButton {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  .submitButton:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
  .submitButton:active {
    filter: brightness(0.8);
  }
  .cardWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .cardLeft,
  .cardRight {
    width: 45%;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    resize: vertical;
    font-family: inherit;
    padding: 0.5em;
    margin: 0;
    box-sizing: border-box;
    margin: 10px 0;
  }
  input[type="text"],
  input[type="email"] {
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 0.5em;
  }

  .submitWrap {
    display: flex;
    min-height: 50px;
    p {
      display: none;
      font-size: 0.8em;
      margin-left: 10px;
      height: 20px;
    }
  }
}
.Card {
  width: 70vw;
  max-width: 850px;
  margin: 10px 0;
  .skills {
    display: flex;
    flex-wrap: wrap;
  }
  ul {
    padding-left: 20px;
  }
  span {
    background-color: rgba(219, 230, 243, 1);
    margin: 5px 0.5em 0 0;
    padding: 0.3em 0.5em;
    border-radius: 5px;
  }
  .links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    a {
      text-decoration: none;
      color: white;
      background-color: rgba(0, 116, 243, 1);
      padding: 10px;
      border-radius: 5px;
      margin: 0 0.5em 0 0;
      font-size: smaller;
    }
    img {
      padding: 0;
      margin: 0;
      height: 1.2em;
      vertical-align: text-bottom;
    }
  }
}

//LESS THAN PHONE
@media (max-width: $phone) {
  body {
    font-size: 0.8em;
  }
}

//PHONE
@media (min-width: $phone) {
  body {
    font-size: 1em;
  }
  .card {
    min-height: 120px;
  }

  .Home {
    // stage settings for phone
    .stage {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 8vw 0;
      img {
        width: 100%;
      }

      h2,
      h3 {
        margin-bottom: 0;
      }
      .about {
        order: -2;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img {
          width: 100%;
          max-width: 240px;
          // min-width: 150px;
        }
        .spacer {
          display: flex;
          height: 100%;
          width: 100%;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
      .projects {
        display: flex;
        align-items: flex-end;
        background-image: url("./components/Home/projects.png");
        background-position: 160% 0%;
        background-size: 70%;
        background-repeat: no-repeat;
        h2 {
          background-color: white;
          border-radius: 10px;
          padding: 0 10px 0 0;
        }
      }
      .resume {
        display: flex;
        align-items: flex-end;
        background-image: url("./components/Home/resume.png");
        background-position: right;
        background-size: 60%;
        background-repeat: no-repeat;
        h2 {
          background-color: white;
          border-radius: 10px;
          padding: 0 10px 0 0;
        }
      }
      .contact {
        background-image: url("./components/Home/contact.png");
        background-repeat: no-repeat;
        background-position: 200% 20%;
        background-size: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        .front {
          position: absolute;
          z-index: 1;
          h2 {
            background-color: white;
            border-radius: 10px;
            padding: 0 10px 0 0;
          }
        }
        .back {
          visibility: hidden;
          height: 100%;
          width: 100%;
          z-index: -1;
          .socialMedia {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            a {
              width: 15%;
            }
            img {
              margin: 0;
              padding: 0;
              transition: transform 0.2s;
            }
            img:hover {
              transform: scale(1.2);
            }
          }
          .email {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 50%;
            a {
              color: white;
              background-color: black;
              padding: 10px;
              margin: 10px 0;
              border-radius: 10px;
              transition: transform 0.2s;
            }
            a:hover {
              transform: scale(1.1);
            }
          }
        }
      }

      .contact:hover {
        background-image: none;
        transform: rotateY(180deg);
        .front {
          display: none;
        }
        .back {
          visibility: visible;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: rotateY(180deg);
        }
      }

      .description {
        order: -1;
        padding-bottom: 20px;
        line-height: 2em;
        min-height: 9.5em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .Projects {
    .Card {
      width: 100%;
    }
  }

  .About {
    .chart {
      width: 90vw;
      height: 40vh;
    }
  }
}

//TABLET
@media (min-width: $tablet) {
  body {
    font-size: 1.1em;
  }
  .Home {
    .stage {
      img {
        scale: 1.2;
        // max-width: none;
      }
    }
    // stage settings for big screens
    .stage {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      .about {
        grid-column: 1/2;
        grid-row: 1/4;
      }
      .projects {
        grid-column: 2/3;
        grid-row: 1/3;
        background-position: right;
        background-size: 90%;
      }
      .resume {
        grid-column: 3/4;
        grid-row: 1/2;
        background-position: right bottom;
        background-size: 80%;
      }
      .contact {
        grid-column: 3/4;
        grid-row: 2/3;
        .front {
          background-position: 0%;
          background-size: 100%;
        }
      }
      .description {
        grid-column: 2/4;
        grid-row: 3/4;
        min-height: 7.5em;
        display: block;
        text-align: left;
      }
    }
  }

  .About {
    .chart {
      width: 80vw;
      height: 60vh;
    }
  }
}

//COMPUTER
@media (min-width: $computer) {
  body {
    font-size: 1.2em;
  }

  .Home {
    .stage {
      width: 90%;
      img {
        scale: 1.15;
      }
    }
  }

  .About {
    .chart {
      width: 70vw;
      height: 70vh;
    }
  }
}
